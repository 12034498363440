export const showConsoleMessage = () => {
  console.clear();
  console.log(
    `
  %c
                                 .,ccc,
                                .cdxxxo.
                              %c .',;%ccodd;
                             %c ..''''',;,
                              ..........
                 %c       .:oooooooooooooooo:.%c        ..
                 %c      'xWMMMMMMMMMMMMMMMMNd. %c.....%c':ll:.
                 %c     ,OWMMMMMMMMMMMMMMMMMMWx'%c...'''%ccxdc.
                 %c    ;0WMMMMMMMMMMMMMMMMMMMMWO, %c...%c,lo;.
                 %c  .:KMMMMMMMMMMMMMMMMMMMMMMMW0; %c..'%c,.
                 %c .cXMMMMMMMMMMMMMMMMMMMMMMMMMMK:..
                 .oNMMMMMMMMMMMMMMMMMMMMMMMMMMMMXc.
                 :XMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMK,
                 .oNMMMMMMMMMMMMMMMMMMMMMMMMMMMMXl.
                  .lXMMMMMMMMMMMMMMMMMMMMMMMMMMK:.
                   .:KMMMMMMMMMMMMMMMMMMMMMMMW0;
                     ;0WMMMMMMMMMMMMMMMMMMMMWO,
                      ,OWMMMMMMMMMMMMMMMMMMWx'
                       'kWMMMMMMMMMMMMMMMMNd.
                        'xKKKKKKKKKKKKKKKKd.
  %c
    ███╗   ███╗ █████╗ ████████╗███████╗██████╗ ██╗ █████╗ ██╗
    ████╗ ████║██╔══██╗╚══██╔══╝██╔════╝██╔══██╗██║██╔══██╗██║
    ██╔████╔██║███████║   ██║   █████╗  ██████╔╝██║███████║██║
    ██║╚██╔╝██║██╔══██║   ██║   ██╔══╝  ██╔══██╗██║██╔══██║██║
    ██║ ╚═╝ ██║██║  ██║   ██║   ███████╗██║  ██║██║██║  ██║███████╗
    ╚═╝     ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═╝  ╚═╝╚═╝╚═╝  ╚═╝╚══════╝
                    ███████╗ ██████╗ ██████╗  ██╗
                    ██╔════╝██╔════╝ ╚════██╗███║
                    █████╗  ███████╗  █████╔╝╚██║
                    ██╔══╝  ██╔═══██╗██╔═══╝  ██║
                    ███████╗╚██████╔╝███████╗ ██║
                    ╚══════╝ ╚═════╝ ╚══════╝ ╚═╝

  `,
    "background: #222; color: #E831FD",
    "background: #222; color: #FFCDF1",
    "background: #222; color: #E831FD",
    "background: #222; color: #FFCDF1",
    "background: #222; color: #00549F",
    "background: #222; color: #E831FD",
    "background: #222; color: #00549F",
    "background: #222; color: #FFCDF1",
    "background: #222; color: #E831FD",
    "background: #222; color: #00549F",
    "background: #222; color: #FFCDF1",
    "background: #222; color: #E831FD",
    "background: #222; color: #00549F",
    "background: #222; color: #FFCDF1",
    "background: #222; color: #E831FD",
    "background: #222; color: #00549F",
    "background: #222; color: #FFCDF1",
    "background: #222; color: #E831FD",
    "background: #222; color: #00549F",
    "background: #222; color: #FFCDF1",
  );
  console.log(
    `%cWarning
%cThis browser feature is for developers only. Please do not copy-paste any code or run any scripts here. It may cause your account to be compromised.
For more information, visit http://en.wikipedia.org/wiki/Self-XSS`,
    "background: #222; color: #ff9d00; font-size: 2.5em;",
    "background: #222; color: #a86700;",
  );
  console.log(
    `%cProject page: https://github.com/avoonix/material-e621`,
    "color: #E831FD; font-size: 1.2em;"
  )
};
